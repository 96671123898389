<template>
  <v-card>
    <!-- user info -->
    <div class="d-flex flex-column justify-center px-6 border-bottom text-center">
      <!-- user image -->
      <div class="d-flex justify-center mb-3">
        <AvatarImage
          :avatar-key="userAvatarKey"
          error-action="getUserAvatar"
          :avatar="userAvatar" />
      </div>
      <!-- user name -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <p
            class="mb-1 font-weight-medium medium-p cropped-text"
            v-bind="attrs"
            v-on="on">
            {{ userData && userData.name }}
          </p>
        </template>
        <span>
          {{ userData && userData.name }}
        </span>
      </v-tooltip>
      <!-- user email -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <p
            class="mb-4 small-p title-in-modal cropped-text"
            v-bind="attrs"
            v-on="on">
            {{ userData && userData.email }}
          </p>
        </template>
        <span>
          {{ userData && userData.email }}
        </span>
      </v-tooltip>
    </div>

    <div class="nav-card nav-card__account-menu mt-3">
      <UpgradeBtn
        v-if="$vuetify.breakpoint.smAndDown"
        :user-id="userId"
        class="my-2 mx-w-unset" />

      <FeedbackAndTraining
        v-if="$vuetify.breakpoint.smAndDown"
        class="my-2 mx-w-unset" />

      <!-- My Page -->
      <router-link
        :to="getFullLinkForWorkspaces('my-pages')"
        exact>
        <p>
          <span>
            <i class="nav-svg-icon icon-my-pages mr-2" />
          </span>
          Pages
        </p>
      </router-link>

      <!-- My Published Workspace -->
      <router-link
        v-if="isActiveWorkspacePublished"
        :to="{name: ROUTE_WORKSPACE_PAGE_EDIT, params: { pageId: $route.params.wId, }}">
        <p class="text-left">
          <span>
            <i class="nav-svg-icon icon-collections mr-2" />
          </span>
          {{ getActiveWorkspaceName }} Page
        </p>
      </router-link>

      <!-- Account -->
      <router-link
        :to="{ path: '/account' }"
        data-test="my_account_menu_button"
        class="mt-auto mb-auto">
        <p>
          <span>
            <v-icon
              color="lightBlack"
              style="font-size: 16px">
              mdi-account-edit-outline
            </v-icon>
            My Account
          </span>
        </p>
      </router-link>

      <!-- Logout -->
      <p
        @click="logout">
        <span>
          <v-icon
            color="lightBlack"
            style="font-size: 16px">
            mdi-logout
          </v-icon>
          Log Out
        </span>
      </p>
    </div>
  </v-card>
</template>
<script>
import logout from '@/mixins/Logout';
import { getFullLinkForWorkspaces } from '@/utils';
import { ROUTE_WORKSPACE_PAGE_EDIT } from '@/constants';
import {
  mapState, mapGetters,
} from 'vuex';
export default {
  name: 'AppHeaderAccountMenu',
  components: {
    UpgradeBtn: () => import('@/components/App/AppHeader/AppHeaderLayout/AppHeaderLayoutUpgradeBtn'),
    FeedbackAndTraining: () => import('@/components/App/AppHeaderFeedbackAndTraining'),
  },
  mixins: [logout],
  data: () => ({
    ROUTE_WORKSPACE_PAGE_EDIT,
  }),
  computed: {
    ...mapState('UserProfile', ['userAvatar', 'userData', 'userAvatarKey']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters(['userId']),
    ...mapGetters('Workspace', ['isActiveWorkspacePublished', 'getActiveWorkspaceName']),
    ...mapGetters('UserRoles', ['canPublishWs']),

  },
  methods: {
    getFullLinkForWorkspaces,
  },
};
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.v-btn.mx-w-unset {
  width: unset;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    max-width: unset !important;
  }
}
.v-card {
  .border-bottom {
    border-bottom: 1px solid var(--v-lightGrey-base);
  }
}
.nav-svg-icon {
  width: 16px;
}
</style>